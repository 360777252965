import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllMould(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/workorder/mould/moulds')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createMould(ctx, mouldData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/workorder/mould/Create', mouldData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateMould(ctx, mouldData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/workorder/mould/Update', mouldData, { params: { id: mouldData.mould.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteMould(ctx, mouldData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/workorder/mould/Delete', { params: { id: mouldData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
