import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMouldList() {
  // Use toast
  const toast = useToast()


  // Table Handlers
  const tableColumns = [
    { key: 'id', label: "ID", sortable: true },
    { key: 'code', sortable: true },
    { key: 'description', sortable: true },
    { key: 'actions' },
  ]
  const totalItems = ref(0)
  const tableItems = ref([])
  const refMouldListTable = ref(null)
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    store
      .dispatch('pamis-mould/fetchAllMould', {
      })
      .then(response => {
        tableItems.value = response.data;
        totalItems.value = response.data.length;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching mould list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  refetchData();
  return {
    refetchData,
    tableColumns,
    tableItems,
    isSortDirDesc,
    totalItem: totalItems,
    refMouldListTable
  }
}
