<template>
  <b-sidebar id="add-new-mould-sidebar"
             :visible="isAddNewMouldSidebarActive"
             bg-variant="white"
             sidebar-class="sidebar-lg"
             shadow
             backdrop
             no-header
             right
             @hidden="resetForm"
             @change="(val) => $emit('update:is-add-new-mould-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Mould" : actionType == 1 ? " Update Mould" :
            "Delete Mould" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext"
                               name="Mould Code"
                               rules="required">
            <b-form-group label="Mould Code"
                          label-for="mould-code">
              <b-form-input id="mould-code"
                            v-model="currentMould.code" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :disabled="actionType == 2"
                            placeholder="Mould Code" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Description"
                        label-for="mould-description">
            <b-form-input id="mould-description"
                          v-model="currentMould.description" autofocus
                          trim
                          :disabled="actionType == 2"
                          placeholder="Description" />
          </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                      v-if="currentMould == null || currentMould.id == null">
              Add
            </b-button>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                      @click="updateMould"
                      v-if="currentMould != null && currentMould.id != null && actionType == 1">
              Update
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                      v-if="currentMould != null && currentMould.id != null && actionType == 2"
                      variant="outline-danger"
                      @click="deleteMould">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMouldSidebarActive',
    event: 'update:is-add-new-mould-sidebar-active',
  },
  props: {
    isAddNewMouldSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteMould() {
      this.$store.dispatch('pamis-mould/deleteMould', this.currentMould)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-mould-sidebar-active', false)
          this.notification()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Mould Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    updateMould() {
      const mouldRequest = {};
      mouldRequest.mould = this.currentMould;
      this.$store.dispatch('pamis-mould/updateMould', mouldRequest)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-mould-sidebar-active', false)
          // this.$emit('show-success-toast')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Mould Successfully Updated',
              icon: 'BellIcon',
            },
          })
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update Mould Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });

    },
    submitForm() {
      const mouldRequest = {};
      mouldRequest.mould = this.currentMould;
        this.$store.dispatch('pamis-mould/createMould', mouldRequest)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-mould-sidebar-active', false)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Create Mould Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },

  watch: {
    moulddata: {
      handler: function (n, o) {
        if (n == null || n == undefined) {
          this.currentMould = {
            id: null,
            code: null,
            description: null
          }
        } else {
          this.currentMould = n.selectedMould;
        }
      },
      deep: true
    }

  },
  data() {
    return {
      required,
      currentMould: null
    }
  },
  inject: ['moulddata'],
  setup(props, { emit }) {
    const blankMouldData = {
      id: null,
      code: null,
      description: null
    }
    const productionResultTypeData = ref(JSON.parse(JSON.stringify(blankMouldData)))
    const {
      getValidationState,
    } = formValidation(productionResultTypeData)

    return {
      productionResultTypeData,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-mould-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
